import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
// import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"
import { Box, Text, Grid, } from "theme-ui"
import { Typer } from "../components/indextypewriter"
import { animated, useTransition, useSpring, config } from 'react-spring'
import '../styles/style.css'

const IndexPage = ({ data }) => {
  const fade = useSpring({
    config: config.gentle, 
    from: { opacity: 0.01, transform: 'perspective(600px) translate3d(0,-5px,0)' },
    to: async (next) => {
      await next({ opacity: 1, transform: 'perspective(600px) translate3d(0,0px,0)'})
    },
  });
  const AnimatedBox = animated(Box)
  // const transitions = useTransition(data.allStrapiProject.nodes, item => item.slug, {
  const transition = useTransition(data.allStrapiProject.nodes, {
    key: item => item.slug,
    config: config.gentle, 
    trail: 150,
    from: { opacity: 0.01, transform: 'perspective(600px) rotateX(90deg)' },
    enter: { opacity: 1, transform: 'perspective(600px) rotateX(0deg)' },
    leave: { opacity: 0.01, transform: 'perspective(600px) rotateX(90deg)', }
  })

  const whatami = data.strapiFrontpage.ima
  const whatyouare = Object.values(whatami).map(value => value.ima)

  return (
    <Layout>
      <SEO title={'👋'} />
      <AnimatedBox
        style={fade}
        py={3}
        sx={{
        }}>
        <Text
          sx={{
            fontSize: [3, 4, 5],
            fontWeight: 'light',
            lineHeight: 'body',
            display: 'inline'
          }}>
          {data.strapiFrontpage.hello}
          <Typer strings={whatyouare} />
        </Text>
        <Text
          sx={{
            fontSize: [3, 4, 5],
            fontWeight: 'light',
            lineHeight: 'body',
            display: 'block'
          }}>

          You can reach me at
        <Text
            px={2}
            pb={1}
            sx={{
              fontSize: [3, 4, 5],
              color: 'primary',
              fontWeight: 'body',
              lineHeight: 'body',
              display: 'inline',
            }}>
            {data.strapiFrontpage.email}
          </Text>
        </Text>
      </AnimatedBox>
      <Grid gap={[1, 2, 3]} columns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}>
        {transition((style, item) => {
          return (
            <AnimatedBox
              style={style}
              id='projectBox'
              sx={{ position: 'relative', }}
              px={0} py={0}
              key={item.slug}
            >
              <Link to={`/projects/${item.slug}`}>
                <Img css={{ width: '100%', height:'15vw', borderRadius: 5 }} fluid={item.cover.childImageSharp.fluid} />
                <Text sx={{
                  position: 'absolute',
                  width: '85%',
                  left: '50%',
                  top: ['50%','70%'],
                  display: 'inline',
                  transform: 'translate(-50%, -50%)',
                  color: 'muted',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontFamily: 'heading',
                  textRendering: '',
                  fontSize: [0, 0, 0, 1],
                  lineHeight: .95,
                  letterSpacing: ['-.5px'],
                  textShadow: 'project.shadow',
                }}>
                  {item.category}
                  <Text sx={{
                    fontWeight: 'light',
                    lineHeight: 1,
                    textTransform: 'capitalize',
                    fontSize: ['150%','175%'],
                    letterSpacing: '0px',
                    color: 'muted',
                    textShadow: 'project.shadow',
                    pt: 1
                  }}>
                    {item.title}
                  </Text>
                </Text>
              </Link>
            </AnimatedBox>
          )
        }
        )}
      </Grid>
    </Layout>
  );
}

export default IndexPage

export const pageQuery = graphql`  
  query IndexQuery {
    site {
      siteMetadata {
        author
      }
    }
    strapiFrontpage {
      hello
      email
      ima {
        ima
      }
    }
    allStrapiProject (filter: {publish: {eq: true}}, sort: {fields: date, order: DESC}) {
      nodes{
        id
        slug
        title
        category
        date(formatString: "YYYY")
        description
        cover {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 300 cropFocus: CENTER, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      edges {
        node {
          id
          slug
          title
          category
          date(formatString: "YYYY")
          description
          cover {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 300 cropFocus: CENTER, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`